import { CommonModule, NgStyle } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Location } from '@angular/common';
import { SettingsService } from 'src/app/@shared/services/settings.service';
@Component({
  selector: 'app-forms-header',
  templateUrl: './forms-header.component.html',
  standalone: true,
  styleUrls: ['./forms-header.component.sass'],
  imports: [MatProgressBarModule, NgStyle, CommonModule],
})
export class FormsHeaderComponent implements OnInit {
  private loc = inject(Location);
  @Input()
  value = 1;

  logoSrc = this._settingsService.logoSrc$

  constructor (
    private _settingsService: SettingsService
  ) {

  }

  ngOnInit() {
    console.log(this.logoSrc)
  }
  back() {
    this.loc.back();
  }
}
