import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  private readonly baseUrl = environment.apiConfig.baseUrl;

  private formData = new BehaviorSubject<any>(null);
  formData$ = this.formData.asObservable();
  setFormData(input: any) {
    // console.log("input", input)
    this.formData.next(input);
  }

  private readonly html = inject(HttpClient);

  getForms() {
    return this.html.get<any>(`${this.baseUrl}/safe-forms`);
  }
}
