<div class="input-wrapper">
    <mat-label>{{ formFields.label }}</mat-label>
    <mat-form-field appearance="fill" floatLabel="never" class="input-group">
      <input
        matInput
        [value]="value"
        class="padding-left10"
        [matDatepicker]="date"
        (dateChange)="setValue($event)" />
      <mat-datepicker-toggle matSuffix [for]="date">
        <mat-icon></mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #date></mat-datepicker>
    </mat-form-field>
</div>
  