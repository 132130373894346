import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { DashBoardServices } from 'src/app/@shared/services/dashboard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-guest-dashboard',
  templateUrl: './guest-dashboard.component.html',
  styleUrls: ['./guest-dashboard.component.sass']
})
export class GuestDashboardComponent implements OnInit {
  version: string = '';

  data = [{
    title: 'Document_001_2024',
    date_created: 'March 23, 2024 at 3:00 p.m',
    recent_action: 'Reviewed',
    last_update: 'March 23, 2024 at 3:00 p.m.',
    status: 'Pending'
  }]
  resultLength = 0
  cols = ['title', 'date created', 'recent action', 'last update', 'status']

  readonly dateFormat = 'MMMM DD, YYYY [at] hh:mm a'

  constructor(
    private _files: FilesService,
    private _navigation: NavigationService,
    private _auth: AuthenticationService,
    private _dashboardService: DashBoardServices,
    private _router: Router,
  ) { 
    
  }


  ngOnInit(): void {
    this.version = this._dashboardService.appVersion;
    this._files.getGuestFiles().subscribe(({data}) => {
      const { data: files, user } = data
      this.formatFiles(files)
    })
  }

  formatFiles(files: any[]) {
    this.data = files.map(file => { 

      const party = file.workflow.parties.find(
        (party: any) => party.email === this._auth.email
      );
    
      let status = file?.workflow?.status

      if (party?.viewedFormatted && status === 'PENDING') {
        status = 'VIEWED';
      }

      console.log({trail: file?.workflow?.auditTrail})

      const recentAction = status === 'CANCELLED' ? status : file?.workflow?.auditTrail.pop()?.details
      return {
      title: file.name,
      date_created: moment(file.createdAt).format(this.dateFormat),
      recent_action: recentAction,
      last_update: moment(file.updatedAt).format(this.dateFormat),
      status: status ,
      id: file.id
    }});
  }

  openPdf(event: any, data: any) {
    console.log({data})
    this._navigation.setLocation(`sign-secure/${data.id}`)
  }

  logout() {
    this._auth.logout();
    sessionStorage.removeItem('token');
    this._auth.userRole = '';
    this._auth.setRole(this._auth.userRole);
    this._router.navigateByUrl('/login');
  }
}
