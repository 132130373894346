import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  forwardRef,
} from '@angular/core';
import {
  AsyncPipe,
  NgFor,
  NgSwitch,
  NgSwitchCase,
  formatDate,
} from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';

@Component({
  selector: 'forms-date',
  templateUrl: './forms-date.component.html',
  standalone: true,
  styleUrls: ['./forms-date.component.sass'],
  imports: [
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    NgSwitch,
    NgSwitchCase,
    NgFor,
    MatSelectModule,
    MatDatepickerModule,
    MatIconModule,
    MatNativeDateModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormsDateComponent),
      multi: true,
    },
  ],
})
export class FormsDateComponent implements ControlValueAccessor {
  @Input()
  formFields: FormInputProperties = {
    key: '',
    label: '',
    options: [],
    page: 0,
    percent: 0,
    size: 0,
    step: 0,
    type: '',
    x: 0,
    y: 0,
  };
  dateVal: any = '';
  value: any = '';
  change: any = () => {};
  touch: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.change = fn;
  }
  registerOnTouched(fn: any): void {
    this.touch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }
  setValue(e: any) {
    const formattedDate = formatDate(e.value, 'MM/dd/YYYY', 'en-us');
    this.value = new Date(e.value);
    this.dateVal = new Date(e.value);
    this.change(new Date(e.value));
    this.touch();
  }
}
interface FormInputProperties {
  key: string;
  label: string;
  options: any[];
  page: number;
  percent: number;
  size: number;
  step: number;
  type: string;
  x: number;
  y: number;
}
