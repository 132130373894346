import { Pipe, PipeTransform } from '@angular/core';
import Mustache from 'mustache';
@Pipe({
  name: 'parseContent',
  pure: false,
  standalone: true,
})
export class ParseContentPipe implements PipeTransform {
  transform(template: any, content: any) {
    // const findings = template.match(/\(([^)]*)\)[^(]*$/);
    if (!template) return;
    const result = Mustache.render(template, content);
    return result ;
  }
}
