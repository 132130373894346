import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breakLine',
  pure: false,
  standalone: true,
})
export class BreakLinePipe implements PipeTransform {
  transform(value: any): any {
    if (value) return value.replace(/\n/g, '<br>');
  }
}
