import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { LocalizationService } from 'src/app/sign-secure/services/localization.service';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.sass'],
})
export class CompleteComponent implements OnInit {

  lastLocation: string = '' 

  constructor(
    private _navService: NavigationService,
    private _signsecure: SignSecureService,
    private _router: Router,
    private _localize: LocalizationService,
    private _nav: NavigationService
  ) {}

  ngOnInit(): void {
    const prevLocation = this._nav.prevLocation;
    if (prevLocation === '/form-viewer') {
      this.lastLocation = 'SafeForm'
    } else {
      this.lastLocation = this._nav.getLastLocation()
    }
  }

  goBack() {
    this._localize.clearProperty('workflow');

    const prevLocation = this._nav.prevLocation;

    if (prevLocation === "/form-viewer") {
      this._router.navigate(['/templates']).then(() => {
        window.location.reload();
      })
    } else {
      const prev = this._nav.prevLocation !== this._nav.path && this._nav.prevLocation !== 'sign-secure' ? this._nav.prevLocation : '/dashboard';
      this._router.navigateByUrl(prev).then(() => {
        this._signsecure.clearWorkFlowData();
        this._signsecure.clearSavedData();
      });
    }
  }
}
