import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Sanitizer,
  ViewChild,
  inject,
} from '@angular/core';
import { FormsHeaderComponent } from '../forms-header/forms-header.component';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/internal/operators/tap';
import { DomSanitizer } from '@angular/platform-browser';
import { AsyncPipe, CommonModule } from '@angular/common';
import { eif } from 'src/assets/forms/EIF';
import { FormsService } from '../forms.service';
import { ParseContentPipe } from '../pipes/parse-content.pipe';
import { Observable } from 'rxjs/internal/Observable';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FormsInputComponent } from './forms-input/forms-input.component';
import { BreakLinePipe } from '../pipes/break-line.pipe';
import { MatCardModule } from '@angular/material/card';
import jsPDF from 'jspdf';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import { MatSelectModule } from '@angular/material/select';
import { FormsSelectComponent } from './forms-select/forms-select.component';
import { stringToArray } from 'konva/lib/shapes/Text';
import { FormsDateComponent } from './forms-date/forms-date.component';
import { MatRippleModule } from '@angular/material/core';
import moment from 'moment';
@Component({
  selector: 'app-forms-viewer',
  templateUrl: './forms-viewer.component.html',
  standalone: true,
  styleUrls: ['./forms-viewer.component.sass'],
  imports: [
    FormsHeaderComponent,
    AsyncPipe,
    ParseContentPipe,
    FormsInputComponent,
    BreakLinePipe,
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    FormsSelectComponent,
    FormsDateComponent,
    MatRippleModule,
  ],
})
export class FormsViewerComponent implements OnInit {
  private readonly asd = ['{{empLastname}}', '{{empFirstname}}'];
  private fb = inject(FormBuilder);
  eif: string = ''; //: Observable<string> = this._formService.formTemp$ || '';
  html = eif;
  formInputs$ = this._formService.formInput$;
  keysArray: any;
  content: { [key: string]: any } = {};
  formFields: any[] = [];
  formFieldGroups: any = [];
  templateFormGroup = this.fb.group({});
  isLoading = true;
  totalSteps = 1;
  step = 1;
  zoom = 1;
  header = '';
  docTitle = '';
  constructor(private _formService: FormsService) {}
  @ViewChild('pdf') pdf!: ElementRef;
  ngOnInit() {
    this._formService.getForms().subscribe(({ data }) => {
      this.docTitle = data[2].documentTitle;
      this.eif = data[2].html;
      const fieldsArray = data[2].formTemplate.sort((b: any, a: any) => {
        return b.step - a.step;
      });
      this.formFields = fieldsArray;
      this.keysArray = this.keyMapper(
        data[2].formTemplate.map((key: any) => key.key)
      );
      this.formFieldGroups = this.formfieldMapper(fieldsArray);
      this.totalSteps = Object.keys(this.formFieldGroups).length;
      this.html = data[2].html;
      this.createFormGroup();
      this.isLoading = false;
    });
    this._formService.setInputs(this.formFields);
  }

  createFormGroup() {
    Object.keys(this.keysArray).forEach(key => {
      this.templateFormGroup.addControl(
        key,
        new FormControl('', Validators.required)
      );
      switch (key) {
        case 'empGender': {
          this.content['empMaleGender'] = '☐';
          this.content['empFemaleGender'] = '☐';
          break;
        }
        case 'empCivilStatus': {
          this.content['empCivilStatusSingle'] = '☐';
          this.content['empCivilStatusWidow'] = '☐';
          this.content['empCivilStatusMarried'] = '☐';
          break;
        }
        default: {
          this.content[key] = '██████████';
          break;
        }
      }
    });
  }

  setValue(e: any, formControlName: any) {
    switch (formControlName) {
      case 'empGender': {
        if (e.value == 'Male') {
          this.content['empMaleGender'] = '☑';
          this.content['empFemaleGender'] = '☐';
          break;
        }
        this.content['empMaleGender'] = '☐';
        this.content['empFemaleGender'] = '☑';
        break;
      }
      case 'empCivilStatus': {
        this.checkboxSetter(formControlName, e.key);
        break;
      }
      default: {
        if (
          this.templateFormGroup.get(formControlName)?.value instanceof Date
        ) {
          this.content[formControlName] = moment(
            this.templateFormGroup.get(formControlName)?.value
          )
            .format('MM/DD/YYYY')
            .toString();
          break;
        }
        this.content[formControlName] =
          typeof this.templateFormGroup.get(formControlName)?.value == 'string'
            ? this.templateFormGroup.get(formControlName)?.value
            : typeof this.templateFormGroup.get(formControlName)?.value ==
              'object'
            ? e.value
            : this.templateFormGroup.get(formControlName)?.value;
        break;
      }
    }
  }

  private checkboxSetter(fieldName: string, optionKey: string) {
    this.formFields
      .filter(field => field.key == fieldName)
      .map(filteredField => filteredField.options)
      .flat()
      .forEach(option => {
        if (option.key == optionKey) {
          this.content[optionKey] = '☑';
          return;
        }
        this.content[option.key] = '☐';
      });
  }

  stepper(action?: string) {
    if (action == 'next') {
      this.step += 1;
    } else {
      this.step -= 1;
    }
    if (this.docTitle == 'TWI 201 Form v2') {
      this.header =
        this.step == 3
          ? 'Present Address'
          : this.step == 4
          ? 'Permanent Address'
          : this.step == 7
          ? 'Family Member/s'
          : this.step == 8 || this.step == 9
          ? 'Spouse Information'
          : this.step == 10
          ? 'Educational Background'
          : this.step == 11
          ? 'Employment History'
          : this.step == 12 || this.step == 13
          ? 'Emergency Contact Information'
          : '';
    }
  }

  private formfieldMapper(formArray: []) {
    const groupedFields: { [key: string]: [any] } = {};
    formArray.forEach((field: any) => {
      const step = field.step;

      if (groupedFields[step]) {
        groupedFields[step].push(field);
      } else {
        groupedFields[step] = [field];
      }
    });

    return groupedFields;
  }

  private keyMapper(keys: string[]) {
    const obj: { [key: string]: string } = {};
    keys.forEach(key => {
      obj[key] = '';
    });
    return obj;
  }

  createPdf() {
    html2canvas(this.pdf.nativeElement, { useCORS: true }).then(canvas => {
      var data = canvas.toDataURL();
      var docDefinition = {
        content: [
          {
            image: data,
            width: 500,
          },
        ],
      };
      pdfMake.createPdf(docDefinition).download('Score_Details.pdf');
    });
  }
}
