import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  Sanitizer,
  ViewChild,
  inject,
} from '@angular/core';
import { FormsHeaderComponent } from '../forms-header/forms-header.component';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/internal/operators/tap';
import { DomSanitizer } from '@angular/platform-browser';
import { AsyncPipe, CommonModule } from '@angular/common';
import { eif } from 'src/assets/forms/EIF';
import { FormsService } from '../forms.service';
import { ParseContentPipe } from '../pipes/parse-content.pipe';
import { Observable } from 'rxjs/internal/Observable';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FormsInputComponent } from './forms-input/forms-input.component';
import { BreakLinePipe } from '../pipes/break-line.pipe';
import { MatCardModule } from '@angular/material/card';
import jsPDF from 'jspdf';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import { MatSelectModule } from '@angular/material/select';
import { FormsSelectComponent } from './forms-select/forms-select.component';
import { stringToArray } from 'konva/lib/shapes/Text';
import { FormsDateComponent } from './forms-date/forms-date.component';
import { MatRippleModule } from '@angular/material/core';
import moment from 'moment';
import { Router } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsLabelComponent } from './forms-label/forms-label.component';
import Mustache from 'mustache';
import { PdfService } from '../pdf.service';
import { PDFDocument } from 'pdf-lib';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';
@Component({
  selector: 'app-forms-viewer',
  templateUrl: './forms-viewer.component.html',
  standalone: true,
  styleUrls: ['./forms-viewer.component.sass'],
  imports: [
    FormsHeaderComponent,
    AsyncPipe,
    ParseContentPipe,
    FormsInputComponent,
    BreakLinePipe,
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    FormsSelectComponent,
    FormsDateComponent,
    MatRippleModule,
    MatProgressBarModule,
    FormsLabelComponent
  ],
})
export class FormsViewerComponent implements OnInit {
  private completedFields: { [key: string]: boolean } = {};
  private readonly fb = inject(FormBuilder);
  eif: string = '';
  html = eif;
  formData$ = this._formService.formData$;
  keysArray: any;
  content: { [key: string]: any } = {};
  formFields: any[] = [];
  formFieldGroups: any = [];
  templateFormGroup = this.fb.group({});
  isLoading = true;
  totalSteps = 1;
  step = 1;
  zoom = 1;
  header = '';
  docTitle: any = undefined;
  progress=0;
  pdfData: Blob | null = null;
  isSubmitting: boolean = false;
  
  constructor(private signSecureService: SignSecureService, private _formService: FormsService, private _pdfService: PdfService, private _router: Router) {}
  @ViewChild('pdf') pdf!: ElementRef;

  ngOnInit() {
    this._formService.formData$.subscribe(data => {
      this.docTitle = data.documentTitle;
      this.eif = data.html;
      const fieldsArray = data.formTemplate.sort((b: any, a: any) => {
        return b.step - a.step;
      });
      this.formFields = fieldsArray;
      this.keysArray = this.keyMapper(
        data.formTemplate.map((key: any) => key.key)
      );
      this.formFieldGroups = this.formfieldMapper(fieldsArray);
      this.totalSteps = Object.keys(this.formFieldGroups).length;
      this.html = data.html;
      this.createFormGroup();
      this.isLoading = false;
    });
  }

  createFormGroup() {
    Object.keys(this.keysArray).forEach(key => {
      this.templateFormGroup.addControl(
        key,
        new FormControl('', Validators.required)
      );
      switch (key) {
        case 'empGender': {
          this.content['empMaleGender'] = '☐';
          this.content['empFemaleGender'] = '☐';
          break;
        }
        case 'empCivilStatus': {
          this.content['empCivilStatusSingle'] = '☐';
          this.content['empCivilStatusWidow'] = '☐';
          this.content['empCivilStatusMarried'] = '☐';
          break;
        }
        default: {
          this.content[key] = '██████████';
          break;
        }
      }
    });
  }

  setValue(e: any, formControlName: any) {
    switch (formControlName) {
      case 'empGender': {
        if (e.value == 'Male') {
          this.content['empMaleGender'] = '☑';
          this.content['empFemaleGender'] = '☐';
          break;
        }
        this.content['empMaleGender'] = '☐';
        this.content['empFemaleGender'] = '☑';
        break;
      }
      case 'empCivilStatus': {
        this.checkboxSetter(formControlName, e.key);
        break;
      }
      default: {
        
        if (
          this.templateFormGroup.get(formControlName)?.value instanceof Date
        ) {
          this.content[formControlName] = moment(
            this.templateFormGroup.get(formControlName)?.value
          )
            .format('MM/DD/YYYY')
            .toString();
          break;
        }
        this.content[formControlName] =
          typeof this.templateFormGroup.get(formControlName)?.value == 'string'
            ? this.templateFormGroup.get(formControlName)?.value
            : typeof this.templateFormGroup.get(formControlName)?.value ==
              'object'
            ? e.value
            : this.templateFormGroup.get(formControlName)?.value;

        this.calculateAddProgress(formControlName);

        break;
      }
    }
  }

  private checkboxSetter(fieldName: string, optionKey: string) {
    this.formFields
      .filter(field => field.key == fieldName)
      .map(filteredField => filteredField.options)
      .flat()
      .forEach(option => {
        if (option.key == optionKey) {
          this.content[optionKey] = '☑';
          return;
        }
        this.content[option.key] = '☐';
      });
  }

  stepper(action?: string) {
    if (action == 'next') {
      this.step += 1;
    } else {
      this.step -= 1;
    }
    if (this.docTitle == 'TWI 201 Form v2') {
      this.header =
        this.step == 3
          ? 'Present Address'
          : this.step == 4
          ? 'Permanent Address'
          : this.step == 7
          ? 'Family Member/s'
          : this.step == 8 || this.step == 9
          ? 'Spouse Information'
          : this.step == 10
          ? 'Educational Background'
          : this.step == 11
          ? 'Employment History'
          : this.step == 12 || this.step == 13
          ? 'Emergency Contact Information'
          : '';
    }
  }

  private formfieldMapper(formArray: []) {
    const groupedFields: { [key: string]: [any] } = {};
    formArray.forEach((field: any) => {
      const step = field.step;

      if (groupedFields[step]) {
        groupedFields[step].push(field);
      } else {
        groupedFields[step] = [field];
      }
    });
    console.log(groupedFields);
    return groupedFields;
  }

  private keyMapper(keys: string[]) {
    const obj: { [key: string]: string } = {};
    keys.forEach(key => {
      obj[key] = '';
    });
    return obj;
  }

  async createPdf() {
    this.isSubmitting = true;
    
    html2canvas(this.pdf.nativeElement, { useCORS: true }).then(async (canvas) => {
      const pageWidth = 595; // A4 width in points
      const pageHeight = 842; // A4 height in points
  
      const pdfDoc = await PDFDocument.create();
      const imageWidth = canvas.width;
      const imageHeight = canvas.height;
      const scale = pageWidth / imageWidth;
      const scaledHeight = imageHeight * scale;
  
      let yOffset = 0;
      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = imageWidth;
      tempCanvas.height = pageHeight / scale;
      const tempCtx = tempCanvas.getContext("2d");
  
      // Loop through the canvas in segments based on scaled page height
      while (yOffset < imageHeight) {
        tempCtx?.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
        
        // Draw a section of the original canvas onto the temp canvas
        tempCtx?.drawImage(canvas, 0, yOffset, imageWidth, pageHeight / scale, 0, 0, imageWidth, pageHeight / scale);
        
        // Convert the cropped section to data URL and embed in PDF
        const sectionDataUrl = tempCanvas.toDataURL();
        const sectionBytes = await fetch(sectionDataUrl).then((res) => res.arrayBuffer());
        const sectionImage = await pdfDoc.embedPng(sectionBytes);
  
        // Add page and draw the current section
        const page = pdfDoc.addPage([pageWidth, pageHeight]);
        page.drawImage(sectionImage, {
          x: 0,
          y: 0,
          width: pageWidth,
          height: pageHeight,
        });
  
        yOffset += pageHeight / scale;
      }
  
      const pdfBytes = await pdfDoc.save();
      const pdfFile = new File([pdfBytes], `${this.docTitle}.pdf`, { type: 'application/pdf' });
      const fileData = await this.processUploadedFile(pdfFile);

      this.signSecureService.setFile(
        pdfFile,
        fileData,
        this.docTitle
      );
      this._router.navigate(['/sign-secure']);
      this.isSubmitting = false;
    });
  }

  // Function to process the PDF file as if it were uploaded
  async processUploadedFile(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = () => {
        const fileData = reader.result as ArrayBuffer; // Ensure it's treated as an ArrayBuffer
        // console.log('File data as ArrayBuffer:', fileData);
        resolve(fileData); // Resolve the Promise with the ArrayBuffer
      };
      
      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };
      
      reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
    });
  }

  calculateAddProgress(formControlName: string) {
    let completedPercentage = this.progress;
  
    const controlValue = this.templateFormGroup.get(formControlName)?.value;
    const field = this.formFieldGroups[1].find((item: any) => item.key === formControlName);
  
    if (field && field.percent) {
      if (controlValue !== "" && !this.completedFields[formControlName]) {
        completedPercentage += field.percent;
        this.completedFields[formControlName] = true;
      } else if (controlValue === "" && this.completedFields[formControlName]) {
        completedPercentage -= field.percent;
        this.completedFields[formControlName] = false;
      }
    }
  
    // Ensure that progress doesn't exceed 100
    this.progress = Math.min(100, Math.max(0, completedPercentage));
    
    // this.cdr.detectChanges();
  }
  
  async onFinish() {
    // console.log("content: ", this.content)
    // console.log("eif: ", this.eif)
    
    // const htmlContent = Mustache.render(this.eif, this.content)
    // const tempDiv = this.renderer.createElement('div');
    // this.renderer.setStyle(tempDiv, 'position', 'absolute');
    // this.renderer.setStyle(tempDiv, 'left', '-9999px');
    // tempDiv.innerHTML = htmlContent;
    // document.body.appendChild(tempDiv);

    // await this.generatePDF(tempDiv);
    // document.body.removeChild(tempDiv);

    var options = {
      format: "Letter",
      orientation: 'fullscreen',
      "border": {
          "top": "0in",
          "right": "0in",
          "bottom": "0in",
          "left": "0in",
      },
      type: "pdf",
      childProcessOptions: {env: {OPENSSL_CONF: '/dev/null'}}
  };

    this._pdfService.generatePdf(this.eif, this.content, options).subscribe((pdfBlob) => {
      console.log("pdfBlob: ", pdfBlob)
      const blobUrl = URL.createObjectURL(pdfBlob);
      console.log("blobUrl: ", blobUrl)
      // window.open(blobUrl);
    });
  }

  async generatePDF(tempDiv: HTMLElement) {
    const canvas = await html2canvas(tempDiv);
    const imgWidth = 208; // A4 width in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const contentDataURL = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4');
    pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);

    // Store the PDF as a Blob instead of directly saving it
    this.pdfData = pdf.output('blob');

    const pdfBlobURL = URL.createObjectURL(this.pdfData);
    window.open(pdfBlobURL);

    // const link = document.createElement('a');
    // link.href = pdfBlobURL;
    // link.download = 'GeneratedDocument.pdf';
    // link.click();
    // URL.revokeObjectURL(pdfBlobURL);
  }
}