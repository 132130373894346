<div class="template-container">
  <div class="tabs-container margin-container">
    <h1>SafeForm Templates</h1>
    <mat-tab-group
      #tabGroup
      preserveContent
      (selectedIndexChange)="loadTab($event)"
      ><mat-tab
        ><ng-template mat-tab-label>Templates</ng-template>
        <mat-card
          *ngIf="tabIndex == 0"
          style="
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          "
          ><span style="padding: 16px 0 0 16px">Internal Forms</span>
          <hr style="width: calc(100% - 1rem)" />
          <ng-container>
            <div
              style="
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-auto-rows: 10rem;
                grid-gap: 3rem;
                padding: 1rem;
              ">
              <mat-card *ngFor="let form of forms | async" 
                (click)="selectForm(form)"><div>{{ form.documentTitle }}</div></mat-card
              >
            </div>
          </ng-container></mat-card
        ></mat-tab
      >
          <!-- <mat-tab
            ><ng-template mat-tab-label>Drafts</ng-template
            ><app-table
              *ngIf="tabIndex == 1"
              [columns]="['Title', 'Date Created', 'Last Update', ' ']"
              [paginate]="true"
              [canOpenFile]="true"
              [hideTitle]="true"></app-table></mat-tab
        > -->
    </mat-tab-group>
  </div>

  <app-template-banner></app-template-banner>
</div>
