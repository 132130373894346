<div class="admin-menu">
  <h6 class="admin-menu-title">Admin Menu</h6>
  <a
    class="admin-menu-item"
    routerLink="/manage-users"
    routerLinkActive="active"
    ariaCurrentWhenActive="page"
    (click)="toggleSideNav()"
  >
    <div class="icon icon-manage-user"></div>
    <p>Manage Users</p></a
  >
  <a
    class="admin-menu-item"
    routerLink="/manage-groups"
    routerLinkActive="active"
    ariaCurrentWhenActive="page"
    (click)="toggleSideNav()"
  >
    <div class="icon icon-manage-groups"></div>
    <p>Manage Groups</p>
  </a>
  <a
    class="admin-menu-item"
    routerLink="/audit-logs"
    routerLinkActive="active"
    ariaCurrentWhenActive="page"
    (click)="toggleSideNav()"
  >
    <div class="icon icon-audit"></div>
    <p>Audit Log</p>
  </a>

  <!-- <div class="admin-menu-item grayed" (click)="updateLocation('reports')"> -->
  <div class="admin-menu-item grayed" *ngIf="!hideItem && userType !== 'admin'">
    <div class="icon icon-report"></div>
    <p>Reports</p>
  </div>
  <a
    class="admin-menu-item"
    routerLink="/settings"
    routerLinkActive="active"
    ariaCurrentWhenActive="page"
    (click)="toggleSideNav()"
    *ngIf="userType !== 'admin'"
  >
    <div class="icon icon-settings"></div>
    <p>Settings</p>
  </a>
</div>
