import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { AsyncPipe, NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'forms-input',
  templateUrl: './forms-input.component.html',
  standalone: true,
  styleUrls: ['./forms-input.component.sass'],
  imports: [
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    NgSwitch,
    NgSwitchCase,
    NgFor,
    MatSelectModule,
    MatDatepickerModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormsInputComponent),
      multi: true,
    },
  ],
})
export class FormsInputComponent implements ControlValueAccessor {
  @Input()
  formFields: FormInputProperties = {
    key: '',
    label: '',
    options: [],
    page: 0,
    percent: 0,
    size: 0,
    step: 0,
    type: '',
    x: 0,
    y: 0,
  };
  value = '';
  change: any = () => {};
  touch: any = () => {};
  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.change = fn;
  }
  registerOnTouched(fn: any): void {
    this.touch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }
  setValue(e: any) {
    this.value = e.target.value;
    this.change(e.target.value);
    this.touch();
  }
}
interface FormInputProperties {
  key: string;
  label: string;
  options: any[];
  page: number;
  percent: number;
  size: number;
  step: number;
  type: string;
  x: number;
  y: number;
}
