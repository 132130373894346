import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import { ActionCallbackData } from 'src/app/@shared/models/Actions.model';
import { Template } from 'src/app/@shared/models/template.model';
import { TemplateService } from 'src/app/@shared/services/template.service';
import { FormsService } from '../../forms/forms.service';

@Component({
  selector: 'app-admin-dashboard-templates',
  templateUrl: './admin-dashboard-templates.component.html',
  styleUrls: ['./admin-dashboard-templates.component.sass'],
})
export class AdminDashboardTemplatesComponent implements OnInit {
  private readonly formService = inject(FormsService);
  private readonly router = inject(Router);
  forms = this.formService.getForms().pipe(
    tap((x: any) => {
      console.log(x);
    }),
    map(data => data.data)
  );
  tabIndex = 0;
  ngOnInit(): void {}
  loadTab(e: any) {
    this.tabIndex = e;
  }
  selectForm(form: any) {
    this.formService.setFormData(form);
    this.router.navigate(['/form-viewer']);
  }
}
