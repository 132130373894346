import { Component, Input, OnInit } from '@angular/core';
import {  NgStyle } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-forms-header',
  standalone: true,
  templateUrl: './forms-header.component.html',
  styleUrls: ['./forms-header.component.sass'],
  imports: [MatProgressBarModule, NgStyle],
})
export class FormsHeaderComponent implements OnInit {
  @Input() value = 1;

  constructor() { }

  ngOnInit(): void {
  }

}
