<div class="header-container">
  <div class="left" (click)="back()">
    <img src="../../../../assets/images/template-icons/back.png" alt="" />
    <a>Back</a>
  </div>
  <div class="custom-progress">
    <mat-progress-bar mode="determinate" [value]="value"></mat-progress-bar>
    <span class="progress">{{ value }}%</span>
  </div>
  <div><img src="../../../../assets/images/r1/r1-logo-w.svg" alt="" class="top-logo" /></div>
</div>
