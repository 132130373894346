<app-forms-header [value]="1"></app-forms-header>
<div class="forms-content">
  <div class="form-input">
    <form [formGroup]="templateFormGroup">
      <ng-container *ngIf="!isLoading">
        <h3>
          <b>{{ header }}</b>
        </h3>
        <ng-container *ngFor="let fields of formFieldGroups[step]">
          <ng-container [ngSwitch]="fields.type">
            <forms-input
              *ngSwitchCase="'input'"
              [formFields]="fields"
              [formControlName]="fields.key"
              (ngModelChange)="setValue($event, fields.key)"></forms-input>
            <forms-select
              [formFields]="fields"
              [formControlName]="fields.key"
              (ngModelChange)="setValue($event, fields.key)"
              *ngSwitchCase="'select'"></forms-select>
            <forms-date
              [formFields]="fields"
              [formControlName]="fields.key"
              (ngModelChange)="setValue($event, fields.key)"
              *ngSwitchCase="'date'"></forms-date></ng-container
        ></ng-container>
      </ng-container>
      <div style="display: flex; gap: 1rem">
        <button
          class="outline-btn"
          *ngIf="step >= 2 && step <= totalSteps"
          (click)="stepper()">
          Back
        </button>
        <button
          class="fill-btn"
          *ngIf="step <= totalSteps - 1"
          (click)="stepper('next')">
          Next
        </button>
      </div>
    </form>
  </div>

  <div class="form-view">
    <div class="center-form">
      <div
        #pdf
        class="viewer"
        [ngStyle]="{ transform: 'scale(' + zoom + ')' }"
        [innerHTML]="eif | parseContent : content | breakLine"></div>
    </div>
    <div class="action-btn">
      <button matRipple (click)="zoom = zoom + 0.25">
        <img
          class="control-btn"
          [style.maskImage]="'url(../../../../assets/images/twi/zoom-in.svg)'"
          alt="" />
      </button>
      <button matRipple (click)="zoom = zoom - 0.25">
        <img
          class="control-btn"
          [style.maskImage]="'url(../../../../assets/images/twi/zoom-out.svg)'"
          alt="" />
      </button>
      <button matRipple (click)="createPdf()">
        <img
          class="control-btn"
          [style.maskImage]="'url(../../../../assets/images/twi/download.svg)'"
          alt="" />
      </button>
      <button matRipple>
        <img
          class="control-btn"
          [style.maskImage]="'url(../../../../assets/images/twi/printer.svg)'"
          alt="" />
      </button>
    </div>
  </div>
</div>
