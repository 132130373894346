<div class="input-wrapper">
    <mat-label>{{ formFields.label }}</mat-label>
    <mat-form-field appearance="fill" floatLabel="never" class="input-group">
      <mat-select
        [value]="value"
        [placeholder]="formFields.label"
        (valueChange)="setValue($event)">
        <mat-option *ngFor="let val of formFields.options" [value]="val">{{
          val.value
        }}</mat-option>
      </mat-select>
    </mat-form-field>
</div>
  