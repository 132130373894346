<div class="header-container">
  <div class="left">
    <img src="../../../../assets/images/template-icons/back.png" alt="" />
    <a>Back to </a>
  </div>
  <div class="custom-progress">
    <mat-progress-bar mode="determinate" [value]="value"></mat-progress-bar>
    <span class="progress">{{ value }}%</span>
  </div>
  <div><img src="../../../../assets/images/twi/twi-small.svg" alt="" /></div>
</div>
