export const eif = `<header class="twHeader" style="padding: 12px 12px 0">
  <table class="table100" style="width: 100%; border-collapse: collapse">
    <tr>
      <td class="table100 text-center" style="width: 100%; text-align: center">
        <img
          class="twHeaderImg"
          style="width: 20%; text-align: center"
          src="https://firebasestorage.googleapis.com/v0/b/unawa-1.appspot.com/o/public%2Fsafeform%2Fassets%2Ftemplate%20assets%2FTWI%20hi-res%20board.png?alt=media&token=78d73bd5-c1d3-4208-bf96-09295a9da52b"
          alt=""
          srcset=""
        />
        <!-- twHeaderDetails old font for title -->
        <p
          class="twPIParagraphLabel"
          style="
            font-family: 'Calibri', sans-serif;
            font-size: 8px;
            margin: 0;
            text-align: center;
          "
        >
          <b
            >Unit 1007 OMM-Citra Bldg, San Miguel Ave. Ortigas Center Pasig
            City, Philippines 1600</b
          >
        </p>
      </td>
    </tr>
  </table>
</header>
<div class="twContentPadding" style="padding: 16px 32px">
  <p
    class="twPIParagraphLabel"
    style="font-size: 8px; font-family: 'Calibri', sans-serif; margin: 0"
  >
    <b><u>PLEASE WRITE IN PRINT</u></b>
  </p>
  <table class="table100" style="width: 100%; border-collapse: collapse">
    <tr>
      <td
        colspan="5"
        class="table100 twtableBorder text-center twtableBG tableAlignTop"
        style="
          width: 100%;
          border: 1px solid #000;
          text-align: center;
          background-color: #000;
        "
      >
        <p
          class="twPIParagraphLabel twFontWhite"
          style="
            font-family: 'Calibri', sans-serif;
            font-size: 8px;
            color: #fff;
            margin: 0;
          "
        >
          <b>PERSONAL INFORMATION</b>
        </p>
      </td>
    </tr>
    <tr>
      <td
        class="table20 twtableBorderLeft tableAlignTop"
        style="width: 20%; border-left: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Last Name</b>
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
          contenteditable>
          {{empLastname}}
        </p>
      </td>
      <td class="table20 tableAlignTop" style="width: 20%; vertical-align: top">
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>First Name</b>
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empFirstname}}
        </p>
      </td>
      <td class="table20 tableAlignTop" style="width: 20%; vertical-align: top">
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Name Ext. <span class="twSpanLabel">(JR/SR/III)</span></b>
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empNameExt}}
        </p>
      </td>
      <td class="table20 tableAlignTop" style="width: 20%; vertical-align: top">
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Middle Name</b>
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empMiddleName}}
        </p>
      </td>
      <td
        class="table20 twtableBorder tableAlignTop text-center"
        style="
          width: 20%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Nick Name</b>
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empNickname}}
        </p>
      </td>
    </tr>
  </table>
  <table class="table100" style="width: 100%; border-collapse: collapse">
    <tr>
      <td
        class="table25 twtableBorder tableAlignTop"
        style="width: 25%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Date of Birth(mm/dd/yyyy)</b>
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empDateOfBirth}}
        </p>
      </td>
      <td
        class="table25 twtableBorder tableAlignTop"
        style="width: 25%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Place of Birth(City/Province)</b>
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empPlaceOfBirth}}
        </p>
      </td>
      <td
        class="table10 twtableBorder tableAlignTop"
        style="width: 10%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Gender</b>
        </p>
        <div class="ml-2" style="margin-top: -2px">
          <p
            class="twPIParagraphLabel"
            style="
              font-family: 'Calibri', sans-serif;
              margin: 0;
              font-size: 7px;
            "
          >
            <span
              class="twPIParagraphLabel"
              style="
                font-family: 'Calibri', sans-serif;
                margin: 0;
                font-size: 12px;
              "
              >{{empMaleGender}}</span
            >
            Male
          </p>
          <p
            class="twPIParagraphLabel"
            style="
              font-family: 'Calibri', sans-serif;
              margin: -2px 0 0;
              font-size: 7px;
            "
          >
            <span
              class="twPIParagraphLabel"
              style="
                font-family: 'Calibri', sans-serif;
                margin: 0;
                font-size: 12px;
              "
              >{{empFemaleGender}}</span
            >
            Female
          </p>
        </div>
      </td>
      <td
        class="table20 twtableBorder tableAlignTop text-center"
        style="
          width: 20%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Civil Status</b>
        </p>
        <table class="table100 ml-2" style="width: 100%; margin-top: -4px">
          <tr>
            <td class="table50 text-left" style="width: 50%; text-align: left">
              <p
                class="twPIParagraphLabel statusTop"
                style="
                  font-family: 'Calibri', sans-serif;
                  font-size: 7px;
                  margin: 0;
                "
              >
                <span
                  class="twPIParagraphLabel"
                  style="
                    font-family: 'Calibri', sans-serif;
                    margin: 0;
                    font-size: 12px;
                  "
                  >{{empCivilStatusSingle}}</span
                >
                Single
              </p>
            </td>
            <td class="table50 text-left" style="width: 50%; text-align: left">
              <p
                class="twPIParagraphLabel statusTop"
                style="
                  font-family: 'Calibri', sans-serif;
                  font-size: 7px;
                  margin: 0;
                "
              >
                <span
                  class="twPIParagraphLabel"
                  style="
                    font-family: 'Calibri', sans-serif;
                    margin: 0;
                    font-size: 12px;
                  "
                  >{{empCivilStatusWidow}}</span
                >
                Widow
              </p>
            </td>
          </tr>
          <tr>
            <td class="table50 text-left" style="width: 50%; text-align: left">
              <p
                class="twPIParagraphLabel statusTop"
                style="
                  font-family: 'Calibri', sans-serif;
                  font-size: 7px;
                  margin: -8px 0 0;
                "
              >
                <span
                  class="twPIParagraphLabel"
                  style="
                    font-family: 'Calibri', sans-serif;
                    margin: 0;
                    font-size: 12px;
                  "
                  >{{empCivilStatusMarried}}</span
                >
                Married
              </p>
            </td>
            <td class="table50 text-left" style="width: 50%"></td>
          </tr>
        </table>
      </td>
      <td
        class="table20 twtableBorder tableAlignTop text-center"
        style="
          width: 20%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Nationality</b>
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empNationality}}
        </p>
      </td>
    </tr>
  </table>
  <table
    class="table100"
    style="width: 100%; border: 1px solid #000; border-collapse: collapse"
  >
    <tr>
      <td
        class="table50 twtableBorder tableAlignTop"
        style="width: 50%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b><u>Present Address</u></b>
        </p>
        <table class="table100" style="width: 100%; border-collapse: collapse">
          <tr>
            <td
              colspan="2"
              class="table100 tableAlignTop"
              style="width: 100%; vertical-align: top"
              style="width: 100%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  font-size: 7px;
                  margin: 0;
                "
              >
                No/Street: {{empPreStreetAddress}}
              </p>
              <div
                class="piDivUnderline1"
                style="
                  border-bottom: 1px solid #000;
                  width: 88%;
                  float: right;
                  margin: 0;
                "
              ></div>
            </td>
          </tr>
          <tr>
            <td
              colspan="2"
              class="table100 tableAlignTop twHeight"
              style="width: 100%; vertical-align: top; height: 22px"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  font-size: 7px;
                  margin: 0;
                "
              >
                Brgy /Town/Municipality {{empPreBrgyTownMun}}
              </p>
              <div
                class="piDivUnderline2"
                style="
                  border-bottom: 1px solid #000;
                  width: 31%;
                  position: absolute;
                  margin-left: 72px;
                  top: 243px;
                "
              ></div>
              <div
                class="piDivUnderline3"
                style="
                  border-bottom: 1px solid #000;
                  width: 43%;
                  position: absolute;
                  top: 252px;
                "
              ></div>
            </td>
          </tr>
          <tr>
            <td
              class="table60 tableAlignTop"
              style="width: 60%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  font-size: 7px;
                  margin: 0;
                "
              >
                City/Province {{empPreCityProvince}}
              </p>
              <div
                class="piDivUnderline4"
                style="border-bottom: 1px solid #000; width: 74%; float: right"
              ></div>
            </td>
            <td
              class="table40 tableAlignTop"
              style="width: 40%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  font-size: 7px;
                  margin: 0;
                "
              >
                Zip Code {{empPreZipCode}}
              </p>
              <div
                class="piDivUnderline5"
                style="border-bottom: 1px solid #000; width: 72%; float: right"
              ></div>
            </td>
          </tr>
        </table>
      </td>
      <td
        class="table50 twtableBorder tableAlignTop"
        style="width: 50%; bottom: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b><u>Permanent Address</u></b>
        </p>
        <table
          class="table100"
          style="width: 100%; bottom: 1px solid #000; border-collapse: collapse"
        >
          <tr>
            <td
              colspan="2"
              class="table100 tableAlignTop"
              style="width: 100%; vertical-align: top"
              style="width: 100%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  font-size: 7px;
                  margin: 0;
                "
              >
                No/Street: {{empPerStreetAddress}}
              </p>
              <div
                class="piDivUnderline1"
                style="border-bottom: 1px solid #000; width: 88%; float: right"
              ></div>
            </td>
          </tr>
          <tr>
            <td
              colspan="2"
              class="table100 tableAlignTop twHeight"
              style="width: 100%; vertical-align: top; height: 22px"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  font-size: 7px;
                  margin: 0;
                "
              >
                Brgy /Town/Municipality {{empPerBrgyTownMun}}
              </p>
            </td>
            <div
              class="piDivUnderline2"
              style="
                border-bottom: 1px solid #000;
                width: 31%;
                position: absolute;
                margin-left: 72px;
                top: 243px;
              "
            ></div>
            <div
              class="piDivUnderline3"
              style="
                border-bottom: 1px solid #000;
                width: 43%;
                position: absolute;
                top: 252px;
              "
            ></div>
          </tr>
          <tr>
            <td
              class="table60 tableAlignTop"
              style="width: 60%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  font-size: 7px;
                  margin: 0;
                "
              >
                City/Province {{empPerCityProvince}}
              </p>
              <div
                class="piDivUnderline4"
                style="border-bottom: 1px solid #000; width: 74%; float: right"
              ></div>
            </td>
            <td
              class="table40 tableAlignTop"
              style="width: 40%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  font-size: 7px;
                  margin: 0;
                "
              >
                Zip Code {{empPerZipCode}}
              </p>
              <div
                class="piDivUnderline5"
                style="border-bottom: 1px solid #000; width: 72%; float: right"
              ></div>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <table
    class="table100"
    style="
      width: 100%;
      width: 100%;
      border: 1px solid #000;
      border-collapse: collapse;
    "
  >
    <tr>
      <td
        class="table25 twtableBorder tableAlignTop text-center"
        style="
          width: 25%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b><u>Landline Number</u></b>
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empLandline}}
        </p>
      </td>
      <td
        class="table25 twtableBorder tableAlignTop text-center"
        style="
          width: 25%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b><u>Mobile Number</u></b>
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empMobile}}
        </p>
      </td>
      <td
        class="table25 twtableBorder tableAlignTop text-center"
        style="
          width: 25%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b><u>Personal Email</u></b>
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empEmail}}
        </p>
      </td>
      <td
        class="table25 twtableBorder tableAlignTop text-center"
        style="
          width: 25%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b><u>Facebook Account/Email</u></b>
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empFacebookAccount}}
        </p>
      </td>
    </tr>
  </table>
  <!-- Government Numbers -->
  <table
    class="table100 twtableBorder"
    style="width: 100%; border: 1px solid #000; border-collapse: collapse"
  >
    <tr>
      <td
        colspan="2"
        class="table100 tableAlignTop"
        style="width: 100%; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 8px"
        >
          <b><u>Government Numbers</u></b>
        </p>
      </td>
    </tr>
    <tr>
      <td class="table50 tableAlignTop" style="width: 50%; vertical-align: top">
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          SSS Number (10digits)
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empSSSNumber}}
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          Tax Identification Number (9digits)
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empTINNumber}}
        </p>
      </td>
      <td class="table50 tableAlignTop" style="width: 50%; vertical-align: top">
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          Philhealth ID Number (12digits)
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empPhilhealthNumber}}
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          Pagibig Number (12digits)
        </p>
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empPagibigNumber}}
        </p>
      </td>
    </tr>
    <!-- <tr>             <td class="table50 twtableBorder tableAlignTop">                 <p class="twPIParagraphLabel" style="font-family:'Calibri', sans-serif; margin: 0;                 font-size: 7px;">                    Tax Identification Number (9digits)                  </p>                 <p class="twPIParagraphLabel" style="font-family:'Calibri', sans-serif; margin: 0;                 font-size: 7px;">                    {{empTINNumber}}                  </p>             </td>             <td class="table50 twtableBorder tableAlignTop">                 <p class="twPIParagraphLabel" style="font-family:'Calibri', sans-serif; margin: 0;                 font-size: 7px;">                    Pagibig Number (12digits)                 </p>                 <p class="twPIParagraphLabel" style="font-family:'Calibri', sans-serif; margin: 0;                 font-size: 7px;">                    {{empPagibigNumber}}                  </p>             </td>         </tr> -->
  </table>
  <!-- FAMILY BACKGROUND -->
  <table
    class="table100 twtableBorder"
    style="width: 100%; border: 1px solid #000; border-collapse: collapse"
  >
    <tr>
      <td
        colspan="4"
        class="twtableBorder text-center twtableBG tableAlignTop"
        style="
          width: 100%;
          border: 1px solid #000;
          text-align: center;
          background-color: #000;
        "
      >
        <p
          class="twPIParagraphLabel twFontWhite"
          style="
            font-family: 'Calibri', sans-serif;
            margin: 0;
            font-size: 8px;
            color: #fff;
          "
        >
          <b>FAMILY BACKGROUND</b>
        </p>
      </td>
    </tr>
    <tr>
      <td
        class="table25 twtableBorder tableAlignTop text-center"
        style="
          width: 25%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Last Name</b>
        </p>
      </td>
      <td
        class="table25 twtableBorder tableAlignTop text-center"
        style="
          width: 25%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>First Name</b>
        </p>
      </td>
      <td
        class="table25 twtableBorder tableAlignTop text-center"
        style="
          width: 25%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Middle Name</b>
        </p>
      </td>
      <td
        class="table25 twtableBorder tableAlignTop text-center"
        style="
          width: 25%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Relationship to Member</b>
        </p>
      </td>
    </tr>
    <tr>
      <td
        class="table25 twtableBorder tableAlignTop"
        style="width: 25%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="
            font-family: 'Calibri', sans-serif;
            margin: -8px 0 0;
            list-style-type: disc;
            white-space: pre-line;
            font-size: 7px;
          "
        >
          {{famLastname}}
        </p>
      </td>
      <td
        class="table25 twtableBorder tableAlignTop"
        style="width: 25%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="
            font-family: 'Calibri', sans-serif;
            margin: -8px 0 0;
            list-style-type: disc;
            white-space: pre-line;
            font-size: 7px;
          "
        >
          {{famFirstname}}
        </p>
      </td>
      <td
        class="table25 twtableBorder tableAlignTop"
        style="width: 25%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="
            font-family: 'Calibri', sans-serif;
            margin: -8px 0 0;
            list-style-type: disc;
            white-space: pre-line;
            font-size: 7px;
          "
        >
          {{famMiddlename}}
        </p>
      </td>
      <td
        class="table25 twtableBorder tableAlignTop"
        style="width: 25%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="
            font-family: 'Calibri', sans-serif;
            margin: -8px 0 0;
            font-size: 7px;
            list-style-type: disc;
            white-space: pre-line;
          "
        >
          {{famRelation}}
        </p>
      </td>
    </tr>
    <tr>
      <td
        colspan="4"
        class="twtableBorder tableAlignTop"
        style="border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>SPOUSE</b>
        </p>
      </td>
    </tr>
    <tr>
      <td colspan="4" class="tableAlignTop" style="vertical-align: top">
        <table class="table100" style="width: 100%">
          <tr>
            <td
              class="table25 tableAlignTop text-center twBorderBottom"
              style="
                width: 25%;
                vertical-align: top;
                text-align: center;
                border-bottom: 1px solid #000;
              "
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                {{spouseLastname}}
              </p>
            </td>
            <td
              class="table25 tableAlignTop text-center twBorderBottom"
              style="
                width: 25%;
                vertical-align: top;
                text-align: center;
                border-bottom: 1px solid #000;
              "
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                {{spouseFirstname}}
              </p>
            </td>
            <td
              class="table25 tableAlignTop text-center twBorderBottom"
              style="
                width: 25%;
                vertical-align: top;
                text-align: center;
                border-bottom: 1px solid #000;
              "
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                {{spouseNameExt}}
              </p>
            </td>
            <td
              class="table25 tableAlignTop text-center twBorderBottom"
              style="
                width: 25%;
                vertical-align: top;
                text-align: center;
                border-bottom: 1px solid #000;
              "
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                {{spouseMiddlename}}
              </p>
            </td>
          </tr>
          <tr>
            <td
              class="table25 tableAlignTop text-center"
              style="width: 25%; vertical-align: top; text-align: center"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                <b>Last Name</b>
              </p>
            </td>
            <td
              class="table25 tableAlignTop text-center"
              style="width: 25%; vertical-align: top; text-align: center"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                <b>First Name</b>
              </p>
            </td>
            <td
              class="table25 tableAlignTop text-center"
              style="width: 25%; vertical-align: top; text-align: center"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                <b>Name Extension(JR/SR/III)</b>
              </p>
            </td>
            <td
              class="table25 tableAlignTop text-center"
              style="width: 25%; vertical-align: top; text-align: center"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                <b>Middle Name</b>
              </p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td colspan="4" class="tableAlignTop" style="vertical-align: top">
        <table class="table100" style="width: 100%">
          <tr>
            <td
              colspan="4"
              class="table60 tableAlignTop"
              style="width: 60%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                Present Address {{spousePresentAddress}}
              </p>
              <div
                class="piDivUnderline6"
                style="border-bottom: 1px solid #000; width: 85%; float: right"
              ></div>
            </td>
            <td
              colspan="2"
              class="table40 tableAlignTop"
              style="width: 40%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                Zip Code {{spouseZipCode}}
              </p>
              <div
                class="piDivUnderline7"
                style="border-bottom: 1px solid #000; width: 84%; float: right"
              ></div>
            </td>
          </tr>
          <tr>
            <td
              colspan="2"
              class="table30 tableAlignTop"
              style="width: 30%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                Landline Number {{spouseLandline}}
              </p>
              <div
                class="piDivUnderline8"
                style="border-bottom: 1px solid #000; width: 70%; float: right"
              ></div>
            </td>
            <td
              colspan="2"
              class="table30 tableAlignTop"
              style="width: 30%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                Mobile Number {{spouseMobile}}
              </p>
              <div
                class="piDivUnderline9"
                style="border-bottom: 1px solid #000; width: 74%; float: right"
              ></div>
            </td>
            <td
              colspan="2"
              class="table40 tableAlignTop"
              style="width: 30%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                Email Address {{spouseEmail}}
              </p>
              <div
                class="piDivUnderline10"
                style="border-bottom: 1px solid #000; width: 74%; float: right"
              ></div>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <!-- Educational Background -->
  <table
    class="table100 twtableBorder"
    style="width: 100%; border: 1px solid #000; border-collapse: collapse"
  >
    <tr>
      <td
        colspan="4"
        class="twtableBorder text-center twtableBG tableAlignTop"
        style="
          width: 100%;
          border: 1px solid #000;
          text-align: center;
          background-color: #000;
        "
      >
        <p
          class="twPIParagraphLabel twFontWhite"
          style="
            font-family: 'Calibri', sans-serif;
            margin: 0;
            font-size: 8px;
            color: #fff;
          "
        >
          <b>EDUCATIONAL BACKGROUND</b>
        </p>
      </td>
    </tr>
    <tr>
      <td
        class="table30 twtableBorder tableAlignTop text-center"
        style="
          width: 30%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>SCHOOL</b>
        </p>
      </td>
      <td
        class="table30 twtableBorder tableAlignTop text-center"
        style="
          width: 30%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>DEGREE/COURSE</b>
        </p>
      </td>
      <td
        class="table20 twtableBorder tableAlignTop text-center"
        style="
          width: 20%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Inclusive Date From</b>
        </p>
      </td>
      <td
        class="table20 twtableBorder tableAlignTop text-center"
        style="
          width: 20%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Inclusive Date To</b>
        </p>
      </td>
    </tr>
    <tr>
      <td
        class="table30 twtableBorder tableAlignTop"
        style="width: 30%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="
            font-family: 'Calibri', sans-serif;
            margin: -8px 0 0;
            list-style-type: disc;
            white-space: pre-line;
            font-size: 7px;
          "
        >
          {{empSchool}}
        </p>
      </td>
      <td
        class="table30 twtableBorder tableAlignTop"
        style="width: 30%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="
            font-family: 'Calibri', sans-serif;
            margin: -8px 0 0;
            list-style-type: disc;
            white-space: pre-line;
            font-size: 7px;
          "
        >
          {{empDegreeCourse}}
        </p>
      </td>
      <td
        class="table20 twtableBorder tableAlignTop"
        style="width: 20%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="
            font-family: 'Calibri', sans-serif;
            margin: -8px 0 0;
            list-style-type: disc;
            white-space: pre-line;
            font-size: 7px;
          "
        >
          {{empInclusiveDateFrom}}
        </p>
      </td>
      <td
        class="table20 twtableBorder tableAlignTop"
        style="width: 20%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="
            font-family: 'Calibri', sans-serif;
            margin: -8px 0 0;
            list-style-type: disc;
            white-space: pre-line;
            font-size: 7px;
          "
        >
          {{empInclusiveDateTo}}
        </p>
      </td>
    </tr>
  </table>
  <!-- EMPLOYMENT HISTORY -->
  <table
    class="table100 twtableBorder"
    style="width: 100%; border: 1px solid #000; border-collapse: collapse"
  >
    <tr>
      <td
        colspan="4"
        class="twtableBorder text-center twtableBG tableAlignTop"
        style="
          width: 100%;
          border: 1px solid #000;
          text-align: center;
          background-color: #000;
        "
      >
        <p
          class="twPIParagraphLabel twFontWhite"
          style="
            font-family: 'Calibri', sans-serif;
            margin: 0;
            font-size: 8px;
            color: #fff;
          "
        >
          <b>EMPLOYMENT HISTORY</b>
        </p>
      </td>
    </tr>
    <tr>
      <td
        class="table30 twtableBorder tableAlignTop text-center"
        style="
          width: 30%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>COMPANY NAME</b>
        </p>
      </td>
      <td
        class="table30 twtableBorder tableAlignTop text-center"
        style="
          width: 30%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>POSITION</b>
        </p>
      </td>
      <td
        class="table20 twtableBorder tableAlignTop text-center"
        style="
          width: 20%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Inclusive Date From</b>
        </p>
      </td>
      <td
        class="table20 twtableBorder tableAlignTop text-center"
        style="
          width: 20%;
          border: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Inclusive Date To</b>
        </p>
      </td>
    </tr>
    <tr>
      <td
        class="table30 twtableBorder tableAlignTop"
        style="width: 30%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="
            font-family: 'Calibri', sans-serif;
            margin: -8px 0 0;
            list-style-type: disc;
            white-space: pre-line;
            font-size: 7px;
          "
        >
          {{empCompanyName}}
        </p>
      </td>
      <td
        class="table30 twtableBorder tableAlignTop"
        style="width: 30%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="
            font-family: 'Calibri', sans-serif;
            margin: -8px 0 0;
            list-style-type: disc;
            white-space: pre-line;
            font-size: 7px;
          "
        >
          {{empCompanyPosition}}
        </p>
      </td>
      <td
        class="table20 twtableBorder tableAlignTop"
        style="width: 20%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="
            font-family: 'Calibri', sans-serif;
            margin: -8px 0 0;
            list-style-type: disc;
            white-space: pre-line;
            font-size: 7px;
          "
        >
          {{empCompanyDateFrom}}
        </p>
      </td>
      <td
        class="table20 twtableBorder tableAlignTop"
        style="width: 20%; border: 1px solid #000; vertical-align: top"
      >
        <p
          class="twPIParagraphLabel"
          style="
            font-family: 'Calibri', sans-serif;
            margin: -8px 0 0;
            list-style-type: disc;
            white-space: pre-line;
            font-size: 7px;
          "
        >
          {{empCompanyDateTo}}
        </p>
      </td>
    </tr>
  </table>
  <!-- IN CASE OF EMERGENCY -->
  <table
    class="table100 twtableBorder"
    style="width: 100%; border: 1px solid #000; border-collapse: collapse"
  >
    <tr>
      <td
        colspan="5"
        class="table100 twtableBorder text-center twtableBG tableAlignTop"
        style="
          width: 100%;
          border: 1px solid #000;
          text-align: center;
          background-color: #000;
        "
      >
        <p
          class="twPIParagraphLabel twFontWhite"
          style="
            font-family: 'Calibri', sans-serif;
            margin: 0;
            font-size: 8px;
            color: #fff;
          "
        >
          <b>IN CASE OF EMERGENCY</b>
        </p>
      </td>
    </tr>
    <tr>
      <td
        class="table20 twBorderBottom tableAlignTop text-center"
        style="
          width: 20%;
          border-bottom: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empGuardianLastname}}
        </p>
      </td>
      <td
        class="table20 twBorderBottom tableAlignTop text-center"
        style="
          width: 20%;
          border-bottom: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empGuardianFirstname}}
        </p>
      </td>
      <td
        class="table20 twBorderBottom tableAlignTop text-center"
        style="
          width: 20%;
          border-bottom: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empGuardianNameExt}}
        </p>
      </td>
      <td
        class="table20 twBorderBottom tableAlignTop text-center"
        style="
          width: 20%;
          border-bottom: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empGuardianMiddlename}}
        </p>
      </td>
      <td
        class="table20 twBorderBottom tableAlignTop text-center"
        style="
          width: 20%;
          border-bottom: 1px solid #000;
          vertical-align: top;
          text-align: center;
        "
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          {{empGuardianRelationship}}
        </p>
      </td>
    </tr>
    <tr>
      <td
        class="table20 tableAlignTop text-center"
        style="width: 20%; vertical-align: top; text-align: center"
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Last Name</b>
        </p>
      </td>
      <td
        class="table20 tableAlignTop text-center"
        style="width: 20%; vertical-align: top; text-align: center"
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>First Name</b>
        </p>
      </td>
      <td
        class="table20 tableAlignTop text-center"
        style="width: 20%; vertical-align: top; text-align: center"
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Name Extension(JR/SR/III)</b>
        </p>
      </td>
      <td
        class="table20 tableAlignTop text-center"
        style="width: 20%; vertical-align: top; text-align: center"
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Middle Name</b>
        </p>
      </td>
      <td
        class="table20 tableAlignTop text-center"
        style="width: 20%; vertical-align: top; text-align: center"
      >
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          <b>Relationship to Member</b>
        </p>
      </td>
    </tr>
    <tr>
      <td colspan="5" class="tableAlignTop" style="vertical-align: top">
        <table class="table100" style="width: 100%">
          <tr>
            <td
              colspan="4"
              class="table60 tableAlignTop"
              style="width: 60%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                Present Address {{guardianPresentAddress}}
              </p>
              <div
                class="piDivUnderline6"
                style="border-bottom: 1px solid #000; width: 83%; float: right"
              ></div>
            </td>
            <td
              colspan="2"
              class="table40 tableAlignTop"
              style="width: 40%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                Zip Code {{guardianZipCode}}
              </p>
              <div
                class="piDivUnderline7"
                style="border-bottom: 1px solid #000; width: 86%; float: right"
              ></div>
            </td>
          </tr>
          <tr>
            <td
              colspan="2"
              class="table30 tableAlignTop"
              style="width: 30%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                Landline Number {{guardianLandline}}
              </p>
              <div
                class="piDivUnderline8"
                style="border-bottom: 1px solid #000; width: 66%; float: right"
              ></div>
            </td>
            <td
              colspan="2"
              class="table30 tableAlignTop"
              style="width: 30%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                Mobile Number {{guardianMobile}}
              </p>
              <div
                class="piDivUnderline9"
                style="border-bottom: 1px solid #000; width: 71%; float: right"
              ></div>
            </td>
            <td
              colspan="2"
              class="table40 tableAlignTop"
              style="width: 40%; vertical-align: top"
            >
              <p
                class="twPIParagraphLabel"
                style="
                  font-family: 'Calibri', sans-serif;
                  margin: 0;
                  font-size: 7px;
                "
              >
                Email Address {{guardianEmail}}
              </p>
              <div
                class="piDivUnderline10"
                style="border-bottom: 1px solid #000; width: 78%; float: right"
              ></div>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <table class="table100 mt-3" style="width: 100%">
    <tr>
      <td colspan="2" class="table100" style="width: 100%">
        <p
          class="twPIParagraphLabel"
          style="font-family: 'Calibri', sans-serif; margin: 0; font-size: 7px"
        >
          I hereby certify that the information provided above is true and
          correct. I understand that any false or misleading information may
          result in my release from the Agency.
        </p>
      </td>
    </tr>
    <tr>
      <td
        class="pl-5 table50 text-center twTDHeight tableAlignBottom"
        style="
          padding-left: 16px;
          width: 50%;
          text-align: center;
          height: 65px;
          vertical-align: bottom;
        "
      >
        <div
          class="piSigUnderline"
          style="
            border-top: 1px solid #000;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <p
            class="twPIParagraphLabel"
            style="font-family: 'Calibri', sans-serif; font-size: 7px"
          >
            Signature over Printed Name
          </p>
        </div>
      </td>
      <td
        class="pr-5 table50 text-center twTDHeight tableAlignBottom"
        style="
          padding-right: 16px;
          width: 50%;
          text-align: center;
          height: 65px;
          vertical-align: bottom;
        "
      >
        <div
          class="piSigUnderline"
          style="
            border-top: 1px solid #000;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <p
            class="twPIParagraphLabel"
            style="font-family: 'Calibri', sans-serif; font-size: 7px"
          >
            Date
          </p>
        </div>
      </td>
    </tr>
  </table>
</div>
`;
