import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(private _http: HttpClient) { }

  generatePdf(template: string, content: any, options: any) {
    return this._http.post('http://localhost:3000/generate-pdf', {
      template,
      content,
      options,
    }, { responseType: 'blob'});
  }
}
